.searchForm {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media(max-width:1160px) {
        width: 60%;
        margin: 0 auto;
        grid-template-columns: repeat(1, 1fr);
    }

    @media(max-width:768px) {
        width: 80%;
    }

    @media(max-width:500px) {
        width: 100%;
    }

    & input {
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 1rem;
        font-weight: normal;

        &::placeholder {
            color: #6b7280;
        }

        @media(max-width:768px) {
            height: 45px;
        }
    }

    & .neighHood {
        top: 5px;
        color: #fff;
        position: relative;
        font-size: 18px;
    }

    & .locationInput {
        flex-wrap: wrap;

        &>div {
            border-bottom: none;

            &:nth-child(3) {
                height: 300px;
                overflow-y: auto;
            }

            & svg {
                display: none;
            }
        }

        & input {
            border-bottom: 1px solid #fff;
        }
    }

    & .dateInput {
        &>div {
            &>div {
                &>div {
                    &>div {
                        &>div {
                            background-color: rgba(255, 0, 0, 0);

                            & input {
                                font-size: 1rem;
                                font-weight: normal;
                                background-position: 8px 15px;

                                &::placeholder {
                                    color: #6b7280;
                                }
                            }
                        }
                    }
                }
            }
        }
    }



    & .taskWrapper {
        width: 249px;

        @media(max-width: 1160px) {
            width: 100%;
            min-width: 100%;
        }

        &>div {
            width: 100%;

            &:global(.p-multiselect:not(.p-disabled).p-focus) {
                border-color: #80bdff;
                box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
            }


        }
    }


    & .searchButton {
        & button {
            padding: 8px 20px;
            font-size: 15px;
            background-color: var(--marketplaceColor);
            border: 1px solid var(--marketplaceColor);
            border-radius: 4px;
            color: white;
            text-decoration: none;
            box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
            font-weight: 500;
            min-height: 50px;
        }
    }
}

:global(.p-multiselect-items-wrapper) {
    padding: 0 15px;
}

.mainOptionHeading {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
}

.multiSelect {
    /* background-color: white;
    max-width: 17rem;
    width: 17rem;
    height: 48px;
    border-radius: 7px;
    padding-left: 13px;
    padding-top: 10px;
    padding-right: 13px;
    & .p-multiselect-trigger{
        position: relative !important;
        top: -6px !important;
    }
    & .p-multiselect-items-wrapper{
        background-color: white !important;
    } */
}