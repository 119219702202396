@import '../../../../styles/customMediaQueries.css';

.carouselContainer {
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  padding: 64px 0 0 0;

  &:hover .carouselArrows {
    opacity: 1;
  }

  /* smartphones, touchscreens: we don't need to show arrows */
  @media (any-hover: none) and (pointer: coarse) {
    &:hover .carouselArrows {
      opacity: 0;
    }
  }
}

.carouselArrows {
  opacity: 0;
  z-index: 2;
  transition: all ease-in-out 500ms;
}

.carouselArrowPrev,
.carouselArrowNext {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;

  width: 48px;
  height: 48px;
  padding: 0px 0 4px 0;
  margin-top: -64px;
  border-radius: 100%;
  border: none;

  background-color: lightgrey;
  opacity: 0.9;
  font-size: 30px;
  color: black;
  transition: all ease-in-out 100ms;

  &:hover {
    opacity: 1;
    cursor: pointer;
    background: black;
    color: white;
  }
}

.carouselArrowPrev {
  left: 48px;
}

.carouselArrowNext {
  right: 48px;
}

.notEnoughBlocks {
  @media (--viewportMedium) {
    display: none;
  }
}

.baseCarousel {
  display: flex;
  flex-wrap: nowrap;
  width: var(--carouselWidth);
  padding-bottom: 24px;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.oneColumn,
.twoColumns,
.threeColumns,
.fourColumns {
  composes: baseCarousel;
}

.block {
  flex: 0 0 auto;
  /* 64px = horizontal layout paddings */
  width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

  margin-right: 16px;
  scroll-snap-align: center;

  transform: translateX(32px);

  &:last-of-type {
    padding-right: 32px;
    /* 32px (padding-right above) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
  }

  @media (--viewportMedium) {
    margin-right: 32px;
  }

  @media (min-width: 1200px) {
    /* Offset the start of the carousel so it follows the global grid layout (e.g. (1400 - 1200) / 2 = 100px) */
    /* Removing this uses the full page width for the slider pane */
    transform: translateX(max(calc((var(--carouselWidth) - var(--contentMaxWidthPages)) / 2 + 32px), 0px));

    &:last-of-type {
      padding-right: 32px;
      width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
      /* 32px (padding-right above) */
    }
  }
}

.oneColumn .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

    &:last-of-type {
      /* 32px (padding-right) */
      width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
    }
  }
}

.twoColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (one gutter á 32px) / 2 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 32px) / 2);

    &:last-of-type {
      /* 32px (padding-right above) / 2 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 2);
    }
  }
}

.threeColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (two gutters á 32px) / 3 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 64px) / 3);

    &:last-of-type {
      /* 32px (padding-right above) / 3 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 3);
    }
  }
}

.fourColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 96px (three gutters á 32px) / 4 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 96px) / 4);

    &:last-of-type {
      /* 32px (padding-right above) / 4 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 4);
    }
  }
}

.featureListingWrapper {
  padding-bottom: 32px;

  @media(--viewportMedium) {
    padding-bottom: 64px;
  }

  & .featureHeading {
    margin-top: 0;
    font-size: 32px;
    line-height: 1.33;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;
    color: var(--colorGrey900);
  }

  & .featureGrid {
    margin: 0 auto;
    /* gap: 32px; */
    /* display: grid;
    grid-template-columns: repeat(4, 1fr); */
    max-width: var(--contentMaxWidthPages);

    & :global(.slick-slide) {
      &>div {
        margin: 0 10px;
      }
    }

    & :global(.slick-arrow) {
      background-color: black;
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;
      border-radius: 50%;
      top: 35%;

      & svg {
        z-index: 9;
        color: #fff;
      }

      &:before {
        display: none;
      }
    }

    @media(max-width: 1160px) {
      & :global(.slick-prev) {
        left: 20px;
      }

      & :global(.slick-next) {
        right: 15px;
      }
    }

    & .featureCardBox {

      cursor: pointer;

      & .featureImage {
        width: 100%;
        height: 260px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid #ddd;
      }

      & .featurePrice {
        font-size: 15px;
        margin-bottom: 8px;
        color: var(--marketplaceColor);
      }

      & .featureTitle {
        font-size: 24px;
        line-height: 1.33;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
        color: var(--colorGrey900);
      }

      & .featureDescription {
        line-height: 1.66;
        font-size: 16px;
        font-weight: var(--fontWeightMedium);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.moreListingLink {
  text-align: right;

  & a {
    display: inline-block;
    padding: 8px 20px;
    font-size: 15px;
    background-color: var(--marketplaceColor);
    border-radius: 4px;
    color: white;
    text-decoration: none;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    font-weight: 500;
    margin-top: 24px;
  }
}