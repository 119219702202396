@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.featuresMain {
  composes: baseColumn;
}

.heroWrapper {
  composes: baseColumn;

  @media(max-width: 768px) {
    padding-top: 0;
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-auto-flow: dense;
  gap: 0;

  @media (--viewportMedium) {
    gap: 64px;
    flex-direction: row-reverse;
  }

  &:nth-child(even) {
    @media (--viewportMedium) {
      flex-direction: row;
    }
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.searchForm {
  gap: 20px;
  display: flex;
  align-items: center;

  & .inputForm {
    & input {}
  }

  & .submitButton {
    & button {
      padding: 8px 20px;
      font-size: 15px;
      background-color: var(--marketplaceColor);
      border: 1px solid var(--marketplaceColor);
      border-radius: 4px;
      color: white;
      text-decoration: none;
      box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
      font-weight: 500;
    }
  }
}
