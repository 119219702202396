@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }

  &:target {
    scroll-margin-top: var(--topbarHeight);

    @media (--viewportMedium) {
      scroll-margin-top: var(--topbarHeightDesktop);
    }
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.welcomeHeading {
  padding: 15px 0;
  position: relative;
  word-break: break-word;

  & h2 {
    color: #fff;
    padding: 0;
    margin: 0;
    font-size: 18px;
    text-transform: capitalize;
  }
}